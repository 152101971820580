body.login {
    @apply bg-blush;

    #login h1 a {
        width: 86px;
        height: 52px;
        background-size: 100% 100%;
        background-image: url('../../images/logo-small.svg');
    }

    #loginform {
        border: 1px solid #f2f2f2;
    }

    .submit .button {
        @apply bg-pink;
        border-color: theme('colors.pink');
        box-shadow: none;
        transition: all 0.2s ease;

        &:hover {
            @apply bg-pink bg-opacity-90;
            border-color: theme('colors.pink');
        }
    }
}
